





































































































import Vue from "vue";
// import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { InterestObject, AttendeeDataObject } from "@/types/interfaces";

export default Vue.extend({
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 20
                    },
                    1336: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 10
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        };
    },
    props: {
        useIcons: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: "My Special Interests"
        },
        labelClasses: {
            type: String,
            default: "text-white font-heading uppercase leading-snug"
        },
        attendeeKey: {
            type: String,
            default: "specialInterests"
        },
        allItems: {
            type: Array as () => Array<InterestObject>,
            default() {
                return [];
            }
        },
        attendeeData: {
            type: Object as () => AttendeeDataObject
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },

    computed: {
        myInterests(): Array<string> {
            const key = this.attendeeKey as keyof AttendeeDataObject;

            return Array.isArray(this.attendeeData[key])
                ? (this.attendeeData[key] as Array<string>)
                : ([] as Array<string>);
        },

        // getters
        interestObjects(): Array<InterestObject> {
            const items = this.allItems
                .filter((item: InterestObject) =>
                    this.myInterests.includes(item.name)
                )
                .sort((item: InterestObject, nxt: InterestObject) => {
                    return item.name.localeCompare(nxt.name);
                });

            return items;
        }
    }
});
