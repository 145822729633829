<template>
    <div class="bg-white px-8 py-6">
        <Spinners v-if="isLoading" />
        <b-notification
            v-else-if="submitSucess"
            type="is-success"
            has-icon
            aria-close-label="Close notification"
            @close="handleCancel"
        >
            <p>Profile updated.</p>
            <b-button @click="goHome()">Go to homepage</b-button>
            <b-button class="mt-2 md:mt-0 md:ml-2" @click="handleCancel()"
                >Back to profile</b-button
            >
        </b-notification>
        <b-notification
            v-else-if="submitError"
            type="is-danger"
            has-icon
            aria-close-label="Close notification"
            @close="resetSubmitFlags"
        >
            <b>Sorry</b>, there was an error updating the profile.
        </b-notification>
        <form v-else @submit.prevent="handleSubmit">
            <h2>Edit Profile</h2>

            <div class="mb-4">
                <label
                    :for="`email-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Email
                </label>
                <input
                    v-model="updatePayload.email"
                    :id="`email-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="email"
                />
            </div>
            <fieldset class="mb-4">
                <div class="flex items-start text-sm mb-2">
                    <div class="flex items-center">
                        &#8203;
                        <input
                            :id="`displayEmail-${_uid}`"
                            v-model="updatePayload.displayEmail"
                            type="checkbox"
                            class="form-checkbox border h-4 w-4"
                            autocomplete="off"
                        />
                    </div>
                    <label :for="`displayEmail-${_uid}`" class="ml-1"
                        >Display email</label
                    >
                </div>
            </fieldset>

            <div class="mb-4">
                <label
                    :for="`title-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Title
                </label>
                <input
                    v-model="updatePayload.title"
                    :id="`title-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="text"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`phone-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Phone
                </label>
                <input
                    v-model="updatePayload.phone"
                    :id="`phone-${_uid}`"
                    inputmode="numeric"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="text"
                />
            </div>
            <fieldset class="mb-4">
                <div class="flex items-start text-sm mb-2">
                    <div class="flex items-center">
                        &#8203;
                        <input
                            :id="`displayPhone-${_uid}`"
                            v-model="updatePayload.displayPhone"
                            type="checkbox"
                            class="form-checkbox border h-4 w-4"
                            autocomplete="off"
                        />
                    </div>
                    <label :for="`displayPhone-${_uid}`" class="ml-1"
                        >Display Phone number</label
                    >
                </div>
            </fieldset>
            <div
                v-for="social in allowedSocials"
                :key="social.slug"
                class="mb-4"
            >
                <label
                    :for="`${social.slug}-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    {{ social.title }}
                </label>
                <input
                    v-model="updatePayload[social.slug]"
                    :id="`${social.slug}-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4" v-for="field in bioExtraFields" :key="field">
                <label
                    :for="`${field}-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    {{ specialSectionLabels[field] }}
                </label>
                <textarea
                    v-model="updatePayload[field]"
                    :id="`${field}-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    rows="5"
                    :placeholder="specialSectionPlaceholders[field]"
                ></textarea>
            </div>
            <fieldset v-if="pageOptions.imExhibiting" class="mb-4">
                <legend class="block text-sm font-bold mb-2">
                    Are you exhibiting?
                </legend>
                <div class="flex items-start text-sm mb-2">
                    <div class="flex items-center">
                        &#8203;
                        <input
                            :id="`isExhibiting-${_uid}`"
                            v-model="updatePayload.isExhibiting"
                            type="checkbox"
                            class="form-checkbox border h-4 w-4"
                            autocomplete="off"
                        />
                    </div>
                    <label :for="`isExhibiting-${_uid}`" class="ml-1"
                        >I’m exhibiting!</label
                    >
                </div>
            </fieldset>
            <fieldset
                v-if="primaryInterests && primaryInterests.items.length"
                class="mb-4"
            >
                <legend class="block text-sm font-bold mb-2">
                    {{ primaryInterests.label || primaryInterests.title }}
                </legend>
                <div
                    v-for="(item, index) in primaryInterests.items"
                    :key="item.name"
                >
                    <div class="flex items-start text-sm mb-2">
                        <div class="flex items-center">
                            &#8203;
                            <input
                                :id="`interest-${_uid}-${index}`"
                                v-model="updatePayload.specialInterests"
                                :value="item.name"
                                type="checkbox"
                                class="form-checkbox border h-4 w-4"
                                autocomplete="off"
                            />
                        </div>
                        <label
                            :for="`interest-${_uid}-${index}`"
                            class="ml-1"
                            >{{ item.name }}</label
                        >
                    </div>
                </div>
            </fieldset>

            <fieldset
                v-if="secondaryInterests && secondaryInterests.items.length"
                class="mb-4"
            >
                <legend class="block text-sm font-bold mb-2">
                    {{ secondaryInterests.label || secondaryInterests.title }}
                </legend>
                <div
                    v-for="(item, index) in secondaryInterests.items"
                    :key="item.name"
                >
                    <div class="flex items-start text-sm mb-2">
                        <div class="flex items-center">
                            &#8203;
                            <input
                                :id="`sec-interest-${_uid}-${index}`"
                                v-model="updatePayload.secondaryInterests"
                                :value="item.name"
                                type="checkbox"
                                class="form-checkbox border h-4 w-4"
                                autocomplete="off"
                            />
                        </div>
                        <label
                            :for="`sec-interest-${_uid}-${index}`"
                            class="ml-1"
                            >{{ item.name }}</label
                        >
                    </div>
                </div>
            </fieldset>

            <fieldset class="mb-4">
                <legend class="block text-sm font-bold mb-2">
                    Exclusions
                </legend>
                <b-field>
                    <b-checkbox v-model="updatePayload.excludeFromSearch"
                        >Exclude me from search/attendee listings</b-checkbox
                    >
                </b-field>
                <b-field>
                    <b-checkbox v-model="updatePayload.excludeFromMessaging"
                        >Exclude me from 1-to-1 messaging</b-checkbox
                    >
                </b-field>
                <b-field>
                    <b-checkbox v-model="updatePayload.excludeFromAppointments"
                        >Exclude me from appointment scheduling</b-checkbox
                    >
                </b-field>
            </fieldset>

            <b-notification
                class="-mt-2 mb-4"
                v-if="
                    updatePayload.excludeFromSearch &&
                        (!updatePayload.excludeFromMessaging ||
                            !updatePayload.excludeFromAppointments)
                "
                type="is-info"
                :closable="false"
            >
                If you have excluded yourself from search, you will not come up
                in the attendee listing or search boxes for
                messaging/appointments. Users can still send you a message or
                schedule an appointment if you send them a direct link to your
                profile, or if you post a comment anywhere in the platform,
                unless you also explicitly disable those features.
            </b-notification>

            <div class="md:flex items-center justify-between">
                <div class="my-2">
                    <button
                        @click="handleCancel"
                        type="reset"
                        class="block w-full button pill-button button-clear clear-button has-text-weight-bold is-uppercase"
                    >
                        Cancel
                    </button>
                </div>
                <div class="my-2">
                    <button
                        class="block w-full button pill-button is-primary has-text-weight-bold is-uppercase"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { getModule } from "vuex-module-decorators";
import attendeeVuexModule from "@/store/vuex-modules/attendees";
import eventHub from "@/event-hub";
import Spinners from "@/components/utilities/Spinners.vue";

const attendeeStore = getModule(attendeeVuexModule);

const socialObjects = [
    {
        slug: "linkedIn",
        title: "LinkedIn"
    },
    {
        slug: "facebook",
        title: "Facebook"
    },
    {
        slug: "twitter",
        title: "Twitter"
    },
    {
        slug: "instagram",
        title: "Instagram"
    },
    {
        slug: "snapchat",
        title: "Snapchat"
    },
    {
        slug: "youTube",
        title: "YouTube"
    }
];

export default {
    name: "AttendeeProfileEditForm",
    components: {
        Spinners
    },
    data() {
        return {
            isLoading: false,
            submitSucess: false,
            submitError: false,
            valueForReset: {},
            updatePayload: {
                email: "",
                title: "",
                phone: "",
                linkedIn: "",
                facebook: "",
                twitter: "",
                instagram: "",
                snapchat: "",
                youTube: "",
                myAvailability: "",
                biography: "",
                aboutOrganization: "",
                displayPhone: false,
                displayEmail: false,
                specialInterests: [],
                secondaryInterests: [],
                excludeFromSearch: false,
                excludeFromMessaging: false,
                excludeFromAppointments: false,
                isExhibiting: false
            }
        };
    },

    computed: {
        pageOptions() {
            return this.$store.getters.getPageOptions("attendees");
        },

        sectionToUse() {
            return this.pageOptions.section;
        },

        attendeeInfo() {
            return attendeeStore.attendee;
        },

        interestCategories() {
            return this.$store.state.themeConfig.interestCategories;
        },

        primaryInterests() {
            if (
                this.interestCategories &&
                this.interestCategories.enabled &&
                this.interestCategories.categories
            ) {
                const category = this.interestCategories.categories.find(
                    (category) => category.attendeeKey == "specialInterests"
                );

                if (category) {
                    category.items.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });

                    return category;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        secondaryInterests() {
            if (
                this.interestCategories &&
                this.interestCategories.enabled &&
                this.interestCategories.categories
            ) {
                const category = this.interestCategories.categories.find(
                    (category) => category.attendeeKey == "secondaryInterests"
                );

                if (category) {
                    category.items.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });

                    return category;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        bioExtraFields() {
            return attendeeStore.bioExtraFields;
        },

        specialSectionLabels() {
            return this.$store.getters.getAttendeeLabels;
        },

        specialSectionPlaceholders() {
            return {
                myAvailability: this.pageOptions.fieldPlaceholders
                    ?.myAvailability
                    ? this.pageOptions.fieldPlaceholders.myAvailability
                    : "Please share what times / time zone you're available for scheduling meetings",
                biography: this.pageOptions.fieldPlaceholders?.biography
                    ? this.pageOptions.fieldPlaceholders.biography
                    : "",
                aboutOrganization: this.pageOptions.fieldPlaceholders
                    ?.organization
                    ? this.pageOptions.fieldPlaceholders.organization
                    : ""
            };
        },

        allowedSocials() {
            let socials = [...socialObjects];

            const allowed = this.pageOptions.allowedSocials;

            if (Array.isArray(allowed)) {
                socials = socials.filter((social) =>
                    allowed.includes(social.slug)
                );
            }

            return socials;
        }
    },

    created() {
        this.resetData();
        this.initUpdatePayload();

        eventHub.$on("attendee-update-started", this.handleUpdateStarted);
        eventHub.$on("attendee-update-success", this.handleUpdateSuccess);
        eventHub.$on("attendee-update-error", this.handleUpdateError);
        eventHub.$on("attendee-update-done", this.handleUpdateDone);
    },

    beforeDestroy() {
        this.resetSubmitFlags();
        this.setUpdatePayload();

        eventHub.$off("attendee-update-started", this.handleUpdateStarted);
        eventHub.$off("attendee-update-success", this.handleUpdateSuccess);
        eventHub.$off("attendee-update-error", this.handleUpdateError);
        eventHub.$off("attendee-update-done", this.handleUpdateDone);
    },

    methods: {
        initUpdatePayload() {
            attendeeStore.initAttendeeUpdatePayload();

            this.updatePayload = JSON.parse(
                JSON.stringify(attendeeStore.attendeeUpdatePayload)
            );
        },
        resetData() {
            attendeeStore.resetAttendeeUpdatePayload();
            this.updatePayload = JSON.parse(
                JSON.stringify(attendeeStore.attendeeResetData)
            );
        },

        resetSubmitFlags() {
            this.isLoading = false;
            this.submitSucess = false;
            this.submitError = false;
        },
        setUpdatePayload() {
            const payload = this.updatePayload;
            attendeeStore.setAttendeeUpdatePayload(payload);
        },
        handleCancel() {
            this.resetData();
            this.$emit("cancel");
        },
        handleSubmit() {
            this.setUpdatePayload();
            this.$emit("submit");
        },
        handleUpdateStarted() {
            this.isLoading = true;
        },
        handleUpdateSuccess() {
            this.submitSucess = true;
            this.resetData();
        },
        handleUpdateError() {
            this.submitError = true;
        },
        handleUpdateDone() {
            this.isLoading = false;
        },
        goHome() {
            this.$router.push({
                path: "/"
            });
        }
    }
};
</script>
