







































































































































































































































































































































































































































import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";
import store from "@/store";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";

import AttendeeInterestList from "@/components/attendee-profile/AttendeeInterestList.vue";
import NotFound from "@/views/NotFound.vue";
import AttendeeAvatarEditForm from "@/components/attendee-profile/AttendeeAvatarEditForm.vue";
import AttendeeProfileEditForm from "@/components/attendee-profile/AttendeeProfileEditForm.vue";
import AttendeeSocials from "@/components/attendee-profile/AttendeeSocials.vue";
import SessionScheduleItem from "@/components/sessions/SessionScheduleItem.vue";
import MgImage from "@/components/shared/MgImage.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import svgCalendar from "@/components/svg/svg-mini-calendar.vue";
import svgMessage from "@/components/svg/svg-message.vue";

const layoutImagesStore = getModule(layoutImagesVuexModule);
const attendeeStore = getModule(attendeeVuexModule);
const sessionStore = getModule(sessionVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import {
    AttendeeDataObject,
    MasterSessionObject,
    GetAttendeeDataOptions,
    InterestCategory
} from "@/types/interfaces";

export default Vue.extend({
    directives: {
        addbuefymodal: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyModalBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyNotificationAppVersion", el);
            }
        }
    },
    data() {
        return {
            isLoading: true,
            isLoadingAvatar: false,
            isAvatarModalOpen: false,
            isModalOpen: false,
            avatarTimeout: -1,
            isLoadingFavorites: true,
            showFirstTimeText: false,

            attendeeSessions: {} as MasterSessionObject
        };
    },
    components: {
        Spinners,
        NotFound,
        AttendeeAvatarEditForm,
        AttendeeProfileEditForm,
        AttendeeInterestList,
        SessionScheduleItem,
        svgMessage,
        svgCalendar,
        AttendeeSocials,
        MgImage
    },
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    computed: {
        // Getters
        showAttendeeType(): boolean {
            return this.sectionToUse.showAttendeeType !== false;
        },

        attendeeType(): string | undefined {
            const excludedTypes = Array.isArray(
                this.sectionToUse.excludeAttendeeTypes
            )
                ? this.sectionToUse.excludeAttendeeTypes
                : [];

            return !excludedTypes.includes(this.attendeeData.attendeeType)
                ? this.attendeeData.attendeeType
                : "";
        },

        hasExclusion() {
            const data: AttendeeDataObject = this.attendeeData;
            return (
                data.excludeFromSearch ||
                data.excludeFromMessaging ||
                data.excludeFromAppointments
            );
        },
        hasBioContent(): boolean {
            const data: AttendeeDataObject = this.attendeeData;
            const findTrueValue: Array<string> = [];
            this.bioExtraFields.forEach((item: keyof AttendeeDataObject) => {
                if (data[item]) {
                    findTrueValue.push(item);
                }
            });

            return findTrueValue.length ? true : false;
        },

        hasAttendeeSessions(): boolean {
            return Boolean(
                Array.isArray(this.mySessions) && this.mySessions.length
            );
        },

        layoutOptions(): Record<string, any> {
            return store.getters.layoutOptions;
        },

        sectionToUse(): Record<string, any> {
            return store.getters.getPageOptions("attendees");
        },
        layoutImageAttendee(): Record<string, any> {
            return layoutImagesStore.getLayoutImage("attendee");
        },

        awsConfig(): Record<string, any> {
            return store.getters.awsConfig;
        },

        attendeeData(): AttendeeDataObject {
            return attendeeStore.attendee;
        },
        attendeeAvatarImage(): string | unknown {
            return attendeeStore.avatarImage;
        },
        attendeeAvatarFileName(): string | unknown {
            return attendeeStore.avatarFileName;
        },
        hasSocialMediaLinks(): boolean {
            return Boolean(
                this.attendeeData.linkedIn ||
                    this.attendeeData.instagram ||
                    this.attendeeData.facebook ||
                    this.attendeeData.twitter ||
                    this.attendeeData.snapchat ||
                    this.attendeeData.youTube
            );
        },

        addCityStateToOrgName(): boolean {
            return this.sectionToUse.addCityStateToOrgName
                ? this.sectionToUse.addCityStateToOrgName
                : false;
        },

        modifiedOrgName(): string | null | unknown {
            return this.addCityStateToOrgName
                ? [
                      this.attendeeData.companyName,
                      this.attendeeData.city,
                      this.attendeeData.state
                  ]
                      .filter((item) => item && item != "")
                      .join(", ")
                : this.attendeeData.companyName;
        },

        sectionLabels(): string {
            return this.$store.getters.getAttendeeLabels;
        },

        userInfo(): Record<string, any> {
            return this.$store.getters.userInfo;
        },

        isMyProfile(): boolean {
            return (
                this.$store.getters.myBespeakeId ===
                this.attendeeData.attendeeId
            );
        },

        canEdit(): boolean {
            const isSuperUser = store.getters.isSuperUser;
            return Boolean(isSuperUser || this.isMyProfile);
        },

        attendeeApiOptions(): GetAttendeeDataOptions {
            const id = this.id || this.userInfo.id;
            const options = { id: id, isDemoData: false };

            return options;
        },

        attendeeDisplayName(): string {
            let parts = [];

            if (this.sectionToUse.useMiddleInitial) {
                parts = [
                    this.attendeeData.firstName,
                    this.attendeeData.MI,
                    this.attendeeData.lastName
                ];
            } else {
                parts = [
                    this.attendeeData.firstName,
                    this.attendeeData.lastName
                ];
            }

            if (this.sectionToUse.usePrefixAndSuffix) {
                parts.unshift(this.attendeeData.prefix);
            }

            let name = parts
                .filter((part: string | undefined) => {
                    return part && part.trim() != "";
                })
                .join(" ");

            if (
                this.sectionToUse.usePrefixAndSuffix &&
                this.attendeeData.suffix &&
                this.attendeeData.suffix.trim() != ""
            ) {
                name += `, ${this.attendeeData.suffix}`;
            }

            return name;
        },

        useIconButtons(): boolean {
            return this.layoutOptions.usesAttendeeIconButtons
                ? this.layoutOptions.usesAttendeeIconButtons
                : true;
        },

        usesCreateActivityControl(): Record<string, any> {
            const optionChecker = this.$store.getters.isPageOptionActiveInEnv;
            return optionChecker("createActivity", "isActive");
        },

        useSpecialInterests(): boolean {
            return this.sectionToUse.section?.primaryInterests
                ? this.sectionToUse.section?.primaryInterests
                : false;
        },

        bioExtraFields(): Record<string, any> {
            return attendeeStore.bioExtraFields;
        },

        fullAddress(): string {
            const data = this.attendeeData;
            let parts = [data.address1, data.city, data.state, data.zip];

            parts = parts.filter((part) => {
                if (part && typeof part === "string") {
                    return part;
                }
            });

            return parts.join(", ");
        },

        interestCategories(): Array<string> {
            const cat = this.$store.state.themeConfig.interestCategories;

            return cat?.enabled && cat?.categories ? cat.categories : [];
        },

        interestCategoriesWithIcons(): Record<string, any> {
            return this.interestCategories.filter((item: any) => item.useIcons);
        },

        interestCategoriesWithoutIcons(): Record<string, any> {
            return this.interestCategories.filter(
                (item: any) => !item.useIcons
            );
        },

        avatarSrc(): string | unknown {
            let returnValue = "";
            const data = this.attendeeData;
            const images = data.images?.avatar;

            if (images && (images["640x640"] || images["320x320"])) {
                if (images["320x320"]) {
                    returnValue = images["320x320"];
                } else {
                    returnValue = images["640x640"];
                }
            }

            return returnValue;
        },

        avatarSrcSet(): string | unknown {
            const data = this.attendeeData;
            let returnPath = "";
            const set = [];

            if (data.images?.avatar) {
                const urls = data.images.avatar;
                const imgSm = urls["320x320"];
                const imgLg = urls["640x640"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },

        mySessions(): Record<string, any> {
            const upcoming = this.attendeeSessions.upcomingSessions || [];
            const ondemand = this.attendeeSessions.onDemand || [];

            return [...ondemand, ...upcoming];
        },

        messageText(): string {
            return this.attendeeData.excludeFromMessaging
                ? "This user does not wish to receive messages."
                : "Send a message";
        },

        appointmentText(): string {
            return this.attendeeData.excludeFromAppointments
                ? "This user is not accepting invitations."
                : "Request a Meeting";
        },

        firstTimeText(): string {
            return this.$store.getters.firstLoginSettings
                ? this.$store.getters.firstLoginSettings.text
                : "";
        }
    },

    /**
     * lifecycle
     */
    created() {
        this.init();
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    },

    beforeDestroy() {
        // clean up window
        window.clearTimeout(this.avatarTimeout);

        // clean up component
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    },
    methods: {
        /**
         * Methods
         */
        async init() {
            if (!this.userInfo.id) {
                try {
                    await this.$store.dispatch("getUserinfo");
                } catch (error) {
                    console.error(error);
                }
            }

            const attendeePromises = [
                this.fetchAttendee(),
                this.fetchAttendeeSession(),
                attendeeScheduleStore.getFavoriteSessions()
            ];

            let refetchUserInfo = false;
            const isMyProfile = this.userInfo.id === this.$route.params.id;

            if (isMyProfile && !this.userInfo.completedFirstLogin) {
                this.showFirstTimeText = true;
                attendeePromises.push(
                    attendeeStore.putCompletedFirstLogin(this.userInfo.id)
                );
                refetchUserInfo = true;
            }

            Promise.allSettled(attendeePromises).then(() => {
                this.isLoading = false;
                this.isLoadingFavorites = false;

                if (refetchUserInfo) {
                    this.$store.dispatch("getUserinfo");
                }
            });
        },

        fetchAttendeeSession() {
            return sessionStore.getAttendeeSession(this.id).then((data) => {
                const x = data as MasterSessionObject;
                this.attendeeSessions = x;
            });
        },

        fetchAttendee() {
            return attendeeStore.getAttendee(this.attendeeApiOptions);
        },

        showModal() {
            this.isModalOpen = true;
        },

        closeModal() {
            this.isModalOpen = false;
        },

        showAvatarModal() {
            this.isAvatarModalOpen = true;
        },

        closeAvatarModal() {
            this.isAvatarModalOpen = false;
        },

        startFileUpload() {
            this.isLoading = true;

            eventHub.$emit("attendee-avatar-upload-started");

            this.handleFileUpload()
                .then(() => {
                    eventHub.$emit("attendee-avatar-upload-success");
                })
                .catch(() => {
                    eventHub.$emit("attendee-avatar-upload-error");
                })
                .finally(() => {
                    eventHub.$emit("attendee-avatar-upload-done");
                    this.isLoading = false;
                });
        },

        async handleFileUpload() {
            const options = {
                ...this.attendeeApiOptions,
                image: this.attendeeAvatarImage
            } as GetAttendeeDataOptions;

            try {
                this.isLoadingAvatar = true;
                await attendeeStore.uploadAttendeeAvatar(options);

                this.avatarTimeout = window.setTimeout(() => {
                    /**
                     * We currently don't have a way to "listen" for when image processing is done
                     * so we'll wait x seconds. not a good solution.
                     *this is temp code until we can listen for image processing completion.
                     */
                    this.isLoadingAvatar = false;
                }, 5000);

                // to cancel
                // avatarTimeout;
            } catch (error) {
                this.isLoadingAvatar = false;
                throw new Error("Upload failed.");
            }
        },

        handleProfileEditSubmit() {
            this.isLoading = true;
            eventHub.$emit("attendee-update-started");
            attendeeStore
                .putAttendee(this.attendeeApiOptions)
                .then(() => {
                    eventHub.$emit("attendee-update-success");
                })
                .catch(() => {
                    eventHub.$emit("attendee-update-error");
                })
                .finally(() => {
                    eventHub.$emit("attendee-update-done");
                    this.isLoading = false;
                });
        },

        openMessage() {
            if (!this.attendeeData.excludeFromMessaging) {
                this.$router.push({
                    name: "Message-Center",
                    params: {
                        attendeeId: this.$props.id,
                        email: this.attendeeData.email
                            ? this.attendeeData.email
                            : "",
                        firstName: this.attendeeData.firstName
                            ? this.attendeeData.firstName
                            : "",
                        lastName: this.attendeeData.lastName
                            ? this.attendeeData.lastName
                            : "",
                        companyName: this.attendeeData.companyName
                            ? this.attendeeData.companyName
                            : ""
                    }
                });
            }
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        },

        buttonClasses(type = ""): string {
            let classes = this.useIconButtons
                ? "bg-accent-alt rounded-full h-12 w-12 grid duration-300 transition-transform transform hover:scale-125 card-attendee--button"
                : "button pill-button is-primary is-size-4 py-2 has-text-weight-bold uppercase";

            if (type) {
                const messageExclude =
                    type == "message" && this.attendeeData.excludeFromMessaging;
                const apptExclude =
                    type == "appointment" &&
                    this.attendeeData.excludeFromAppointments;

                if (messageExclude || apptExclude) {
                    classes += " pointer-events-none";
                }
            }

            return classes;
        },

        requestMeeting() {
            if (!this.attendeeData.excludeFromAppointments) {
                this.$router.push({
                    path: "/request-meeting"
                });
            }
        }
    }
});
