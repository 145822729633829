<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
    >
        <title>calendar</title>
        <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
                <g>
                    <path
                        d="M6.679 0c-.953 0-1.733.712-1.733 1.582v2.372H8.41V1.582C8.41.712 7.63 0 6.679 0zM21.701 3.954V5.64c0 .927-.751 1.685-1.67 1.685-.919 0-1.67-.758-1.67-1.685V3.954H8.346V5.64c0 .927-.75 1.685-1.67 1.685-.918 0-1.67-.758-1.67-1.685V3.954H0v22.737h26.71V3.954H21.7zm1.67 19.37H3.338V9.007h20.033v14.317zM20.034 0c-.953 0-1.732.712-1.732 1.582v2.372h3.462V1.582c0-.87-.779-1.582-1.73-1.582z"
                        transform="translate(-280 -988) translate(280.264 988.914)"
                    />
                    <path
                        d="M10.628 20.76L5.936 16.385 8.37 14.115 10.628 16.222 16.362 10.874 18.796 13.143z"
                        transform="translate(-280 -988) translate(280.264 988.914)"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-mini-calendar"
};
</script>
