<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="28"
        viewBox="0 0 26 28"
    >
        <title>snapchat</title>
        <path
            d="M13.25 2c2.812-0.031 5.141 1.547 6.312 4.078 0.359 0.766 0.422 1.953 0.422 2.797 0 1-0.078 1.984-0.141 2.984 0.125 0.063 0.297 0.109 0.438 0.109 0.562 0 1.031-0.422 1.594-0.422 0.531 0 1.297 0.375 1.297 1 0 1.5-3.141 1.219-3.141 2.531 0 0.234 0.094 0.453 0.187 0.672 0.75 1.641 2.172 3.219 3.859 3.922 0.406 0.172 0.812 0.266 1.25 0.359 0.281 0.063 0.438 0.266 0.438 0.547 0 1.062-2.703 1.5-3.422 1.609-0.313 0.484-0.078 1.625-0.906 1.625-0.641 0-1.281-0.203-1.969-0.203-0.328 0-0.656 0.016-0.969 0.078-1.859 0.313-2.484 2.312-5.531 2.312-2.938 0-3.641-2-5.453-2.312-0.328-0.063-0.656-0.078-0.984-0.078-0.703 0-1.375 0.234-1.937 0.234-0.875 0-0.609-1.156-0.938-1.656-0.719-0.109-3.422-0.547-3.422-1.609 0-0.281 0.156-0.484 0.438-0.547 0.438-0.094 0.844-0.187 1.25-0.359 1.672-0.688 3.125-2.281 3.859-3.922 0.094-0.219 0.187-0.438 0.187-0.672 0-1.313-3.156-1.062-3.156-2.516 0-0.609 0.719-1 1.266-1 0.484 0 0.969 0.406 1.578 0.406 0.172 0 0.344-0.031 0.5-0.109-0.063-0.984-0.141-1.969-0.141-2.969 0-0.844 0.063-2.047 0.422-2.812 1.375-2.969 3.703-4.047 6.813-4.078z"
        ></path>
    </svg>
</template>
<script>
export default {
    name: "svg-snapchat"
};
</script>
