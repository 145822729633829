


























import Vue from "vue";
import {
    AttendeeDataObject,
    AttendeeSocialMediaObject
} from "@/types/interfaces";

import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgTwitter from "@/components/svg/svg-twitter.vue";
import svgYoutube from "@/components/svg/svg-youtube.vue";
import svgSnapchat from "@/components/svg/svg-snapchat.vue";

export default Vue.extend ({
    data() {
        return {

        }
    },
    components: {
        svgLinkedin,
        svgInstagram,
        svgFacebook,
        svgTwitter,
        svgYoutube,
        svgSnapchat
    },
    props: {
        attendeeData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        attendeeSocials(): Array<AttendeeSocialMediaObject> {
            const socials = [] as Array<AttendeeSocialMediaObject>;

            if (this.attendeeData.linkedIn) {
                socials.push({
                    url: this.attendeeData.linkedIn,
                    slug: "linkedIn",
                    network: "LinkedIn"
                });
            }

            if (this.attendeeData.instagram) {
                socials.push({
                    url: this.attendeeData.instagram,
                    slug: "instagram",
                    network: "Instagram"
                });
            }

            if (this.attendeeData.facebook) {
                socials.push({
                    url: this.attendeeData.facebook,
                    slug: "facebook",
                    network: "Facebook"
                });
            }

            if (this.attendeeData.twitter) {
                socials.push({
                    url: this.attendeeData.twitter,
                    slug: "twitter",
                    network: "Twitter"
                });
            }

            if (this.attendeeData.youTube) {
                socials.push({
                    url: this.attendeeData.youTube,
                    slug: "youTube",
                    network: "YouTube"
                });
            }

            if (this.attendeeData.snapchat) {
                socials.push({
                    url: this.attendeeData.snapchat,
                    slug: "snapchat",
                    network: "Snapchat"
                });
            }

            return socials;
        },

        allowedAttendeeSocials(): Record<string, any> {
            const config = this.$store.getters.getPageOptions("attendees");
            let socials = [...this.attendeeSocials];

            if (config && Array.isArray(config.allowedSocials)) {
                socials = socials.filter((social) =>
                    config.allowedSocials.includes(social.slug)
                );
            }

            return socials;
        }
    },
    methods: {
        componentFromNetworkName(network: string) {
            if (network == "Facebook") {
                return svgFacebook;
            }

            if (network == "LinkedIn") {
                return svgLinkedin;
            }

            if (network == "Twitter") {
                return svgTwitter;
            }

            if (network == "Snapchat") {
                return svgSnapchat;
            }

            if (network == "YouTube") {
                return svgYoutube;
            }

            if (network == "Instagram") {
                return svgInstagram;
            }

            return false;
        },

        componentBg(network: string) {
            const classes = [];

            if (network == "LinkedIn") {
                classes.push("bg-linkedIn");
            } else {
                classes.push(`bg-${network.toLowerCase()}`);
            }

            if (network == "Snapchat") {
                classes.push("text-black");
            } else {
                classes.push("text-white");
            }

            return classes.join(" ");
        }
    }
})
