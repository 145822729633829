<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="37"
        viewBox="0 0 40 37"
    >
        <title>Message</title>
        <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
                <path
                    d="M238.824 988.914c2.683 0 4.837 2.188 4.837 4.912v12.638c0 2.724-2.154 4.911-4.837 4.911h-.66l.485 6.029-5.805-3.484c-.175 2.545-2.286 4.555-4.837 4.555h-.66l.485 6.028-10.025-6.072h-8.881c-2.683 0-4.837-2.189-4.837-4.912v-12.637c0-2.68 2.154-4.912 4.837-4.912h5.98v-2.144c0-2.724 2.154-4.912 4.837-4.912zm-10.817 8.887h-19.081c-1.67 0-3.033 1.383-3.033 3.08v12.638c0 1.695 1.361 3.08 3.032 3.08h9.366l7.475 4.511-.352-4.51h2.593c1.67 0 3.033-1.384 3.033-3.081v-12.637c0-1.696-1.362-3.08-3.033-3.08zm10.817-7.056h-19.083c-1.67 0-3.033 1.384-3.033 3.08v2.145h11.3c2.682 0 4.836 2.187 4.836 4.912l.001 10.94 3.738 2.233-.352-4.511h2.593c1.67 0 3.033-1.384 3.033-3.08v-12.638c0-1.696-1.362-3.08-3.033-3.08zm-12.97 16.458c0 1.977-2.968 1.977-2.968 0s2.968-1.977 2.968 0zm-5.936 0c0 1.977-2.968 1.977-2.968 0s2.968-1.977 2.968 0zm-5.936 0c0 1.977-2.968 1.977-2.968 0s2.968-1.977 2.968 0z"
                    transform="translate(-204 -988)"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-message"
};
</script>
